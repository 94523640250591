import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import HeroSubpage from "components/HeroSubpage"

import { Location, Contact } from "page_components/home"

const LocationPage = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]

  return (
    <Layout
      seo={{
        title: pageData.title,
      }}
      location={location}
    >
      <HeroSubpage
        title={pageData.title}
        description={pageData.acfLocation.heroDescription}
      />

      <Location pageData={pageData} isSubpage />
      <Contact isSubpage />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo1MQ==" } }) {
      nodes {
        id
        title
        acfLocation {
          heroDescription
          locationHeader
          locationInfo
          locationMap {
            description
            time
          }
        }
      }
    }
  }
`

export default LocationPage
